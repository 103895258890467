const config = {
	api: {
		//server: 'https://api-ffb-dev.kerys.co',
		server: 'https://api-ffb.kerys.co',
		root: "/api/v1",
	},
	doAuth: false,
	auth: {
		//url: "https://rel-oidc.ffbatiment.fr/connect/authorize",
		url: "https://oidc.ffbatiment.fr/connect/authorize",
		clientId: "kerys",
	},
	googleAnalytics: "G-ZZZ4X9T9YT",
};

export default config;


import React, {useState, useCallback, useEffect} from "react";
import Helmet from "react-helmet";
import axios from "axios";
import Routes from "./Routes";
import config from "./config";
import {ToastContainer} from "react-toastify";
import Loader from "./components/Loader";
// import ReactGA from "react-ga4";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";


function App() {
	const [userCode, setUserCode] = useState(config.doAuth ? null : "test-test-test-test");
	const [userEmail, setUserEmail] = useState(null);
	const [userFirstName, setUserFirstName] = useState(null);
	const [userLastName, setUserLastName] = useState(null);

	// const [ownMonoCompany] = useState("323505479");
	// const [ownMultiCompanies] = useState([
	// "323505479",
	// "311641849",
	// ]);

	const [userProfile, setUserProfile] = useState(config.doAuth ? null : "no-context"); // mono-contextual | multi-contextual | no-context
	// const [userProfile, setUserProfile] = useState("multi-contextual"); // mono-contextual | multi-contextual | no-context
	// const [userProfile, setUserProfile] = useState("mono-contextual"); // mono-contextual | multi-contextual | no-context
	// const [userProfile, setUserProfile] = useState("no-context"); // mono-contextual | multi-contextual | no-context

	const [userOwnCompanies, setUserOwnCompanies] = useState(null);

	const [userCompanyData, _setUserCompanyData] = useState({
		siren: null,
		companyName: null,
		companyActivityCode: null,
		companyActivityLabel: null,
		companyBTPSectorCode: null,
		companyBTPSectorLabel: null,
		companyBTPSubSectorCode: null,
		companyBTPSubSectorLabel: null,
		companySizeCategory: null,
		companyRegionCode: null,
		companyRegionLabel: null,
	});

	const handleSignin = useCallback(() => {
		const url =
			config.auth.url +
			"?" +
			new URLSearchParams({
				client_id: config.auth.clientId,
				redirect_uri: window.location.origin + "/signin-oidc",
				// state: userCode,
				scope: "openid kerys.api",
				response_type: "code",
			}).toString();

		window.location.replace(url);
	}, []);

	const logUserEvent = useCallback(
		(data) => {
			const reqConfig = {headers: {usercode: data.userCode || userCode}};
			axios.post(config.api.server + config.api.root + "/user-event", data, reqConfig).catch((error) => {
				console.log(error);
			});
		},
		[userCode],
	);

	const setUserSession = useCallback(
		(data) => {
			const reqConfig = {headers: {usercode: userCode}};
			axios.post(config.api.server + config.api.root + "/user-session", data, reqConfig).catch((error) => {
				console.log(error);
			});
		},
		[userCode],
	);

	const setUserData = useCallback((data) => {
		setUserCode(data ? data.id : null);
		setUserEmail(data ? data.email : null);
		setUserFirstName(data ? data.firstName : null);
		setUserLastName(data ? data.lastName : null);
		setUserProfile(data ? data.profile : null);
		setUserOwnCompanies(data ? data.companies : null);
	}, []);

	const setUserCompanyData = useCallback(
		(data) => {
			_setUserCompanyData(data);

			if (window.dataLayer && data) {
				// console.log(window.dataLayer);
				// console.log(data);
				window.dataLayer.push({
					event: "select_company",
					siren: data.siren,
					companyActivityCode: data.companyActivityCode,
					companyBTPSectorLabel: data.companyBTPSectorLabel,
					companyRegionLabel: data.companyRegionLabel,
					companySizeCategory: data.companySizeCategory,
				});
			}

			logUserEvent({
				event: "company",
				eventData: {
					company_activity_code: data.companyActivityCode,
					company_btp_sector_label: data.companyBTPSectorLabel,
					company_region_label: data.companyRegionLabel,
					company_size_category: data.companySizeCategory,
				},
			});

			setUserSession({company: true});
		},
		[logUserEvent, setUserSession],
	);

	const props = {
		userCode,
		userEmail,
		userFirstName,
		userLastName,
		userProfile,
		// ownMonoCompany,
		// ownMultiCompanies,
		userOwnCompanies,
		setUserData,
		userCompanyData,
		setUserCompanyData,
		handleSignin,
		logUserEvent,
		setUserSession,
	};

	// useEffect(() => {
	// ReactGA.initialize(config.googleAnalytics);
	// }, []);

	useEffect(() => {
		if (window.location.pathname !== "/signin-oidc") {
			if (config.doAuth) {
				handleSignin();
			}
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Helmet>
				<title>BatiPerf</title>
				{/*
				<link rel="shortcut icon" href={"/" + appNameForHelmet + "_favicon.ico?v=kubeg3Gef56"} />
				<link rel="apple-touch-icon" sizes="180x180" href={"/" + appNameForHelmet + "_apple-touch-icon.png?v=kubeg3Gef56"} />
				<link rel="icon" type="image/png" sizes="32x32" href={"/" + appNameForHelmet + "_favicon-32x32.png?v=kubeg3Gef56"} />
				<link rel="icon" type="image/png" sizes="16x16" href={"/" + appNameForHelmet + "_favicon-16x16.png?v=kubeg3Gef56"} />
				<link rel="manifest" href={"/" + appNameForHelmet + "_site.webmanifest?v=kubeg3Gef56"} crossOrigin="use-credentials" />
				<link rel="mask-icon" href={"/" + appNameForHelmet + "_safari-pinned-tab.svg?v=kubeg3Gef56"} color="#18107f" />
				<meta name="msapplication-config" content={"/" + appNameForHelmet + "_browserconfig.xml"} />
				*/}
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="theme-color" content="#ffffff" />

				{/*
				<script async src="`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalytics}`"></script>
				<script>
					{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', ${config.googleAnalytics});
					`}
				</script>
				*/}
			</Helmet>

			<ToastContainer
				position="top-right"
				autoClose={2000}
				// hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
				theme="light"
				// transition="bounce"
				icon={false}
			/>

			{userCode || window.location.pathname === "/signin-oidc" ? (
				<Routes {...props} />
			) : (
				<>
					<div className="centerAlign marginTop30">
						<Loader />
					</div>
					<div className="centerAlign marginTop20">Authentification en cours</div>
				</>
			)}
		</>
	);
}

export default App;

import React, {useState, useEffect, useMemo, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import config from "../config";
import axios from "axios";
import {Card, CardTitle} from "./Card";
import Table from "./Table";
import {formatNumberLocaleString, cloneObject, base64ToBytesArray} from "./utils";
import {MainButton, LinkButton, SecondaryButton} from "./Button";
import {StackableModal, ModalBody, ModalCloseButton, ModalFooter} from "./Modal";
import {Select} from "./Select";
import {Chart, highchartsCommonOptions} from "./Chart";
import {metrics, metricParams, regionCodes} from "./metric-parameters";
import InfoTip from "./InfoTip";
import Loader from "./Loader";
import {EditableTextArea} from "./EditableText";
import fileSaver from "file-saver";
import BTPBanqueCard from "./BTPBanqueCard";
import InfoNote from "./InfoNote";
import WarningNote from "./WarningNote";
import {animateScroll as scroll} from "react-scroll";

import "../App.css";
import "./Report.css";

function ActivityCodeManager(props) {
	const {userCode, show, onHide, onChange} = props;

	const [selection, setSelection] = useState(null);
	const [options, setOptions] = useState([]);
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		if (show) {
			setSelection(null);
			setOptions([]);
			setInputValue("");
		}
	}, [show]);

	const getOptions = useCallback(
		(value) => {
			const cleanValue = value.trim().toLowerCase();
			const inputLength = cleanValue.length;

			if (inputLength > 2) {
				const reqConfig = {headers: {usercode: userCode}};
				axios
					.get(config.api.server + config.api.root + "/company/sector/like/" + cleanValue, reqConfig)
					.then((response) => {
						// console.log(response);
						if (response.data.success && response.data.data) {
							setOptions(
								response.data.data.map((data) => ({
									value: data.code_naf,
									label: data.code_naf + " - " + data.libelle_naf,
									// selectedValueLabel: data.siren,
									...data,
								})),
							);
						} else {
							setOptions([]);
						}
					})
					.catch((error) => {
						console.log(error);
						setOptions([]);
					});
			}
		},
		[userCode],
	);

	const handleInputChange = useCallback(
		(value, {action}) => {
			// console.log(value, action);

			if (action === "input-change") {
				getOptions(value);
				setInputValue(value);
			}
		},
		[getOptions],
	);

	const handleSelectOption = useCallback(
		(option) => {
			// const {code_naf, libelle_naf, code_secteur_btp, libelle_secteur_btp, code_sous_secteur_btp, libelle_sous_secteur_btp} = option;
			const {code_naf, libelle_naf} = option;

			getOptions(code_naf);

			setSelection(option);

			setInputValue(code_naf + " - " + libelle_naf);
		},
		[getOptions],
	);

	const handleSave = useCallback(() => {
		onHide();
		onChange(
			selection
				? {
						activityCode: selection.code_naf,
						activityLabel: selection.libelle_naf,
						btpSectorCode: selection.code_secteur_btp,
						btpSectorLabel: selection.libelle_secteur_btp,
						btpSubSectorCode: selection.code_sous_secteur_btp,
						btpSubSectorLabel: selection.libelle_sous_secteur_btp,
					}
				: null,
		);
	}, [onHide, onChange, selection]);

	return (
		<StackableModal dialogClassName="activity-code-manager-dialog" show={show} onHide={onHide} centered keyboard>
			<ModalBody>
				<ModalCloseButton onClick={onHide} />

				<div className="centerAlign marginTop20 marginBottom20">
					<div className="marginBottom15">Indiquez le code NAF auquel vous souhaitez comparer l&apos;entreprise</div>

					<Select
						className="sector-selector"
						searchable
						selectedValue={selection ? selection.code_naf : null}
						options={options}
						onSelectOption={handleSelectOption}
						onInputChange={handleInputChange}
						placeholder=""
						noOptionsMessage={() =>
							inputValue.length > 2 ? "Aucun code NAF trouvé" : "Saisissez un code NAF ou un nom de secteur"
						}
					/>
				</div>
			</ModalBody>

			<ModalFooter>
				<SecondaryButton onClick={onHide}>Annuler</SecondaryButton>
				<MainButton disabled={!selection} onClick={handleSave}>
					Valider
				</MainButton>
			</ModalFooter>
		</StackableModal>
	);
}

function MetricResult(props) {
	const {metric, fractileYears, fractilesData, companyMetricValues, userMetricComments, onUserMetricCommentChange} =
		props;
	// console.log(userMetricComments);

	const metricFractilesData = fractilesData && fractilesData[metric] ? fractilesData[metric] : null;
	var companyMetricData = null;
	const fractileSeriesData = [];
	const companySeriesData = [];

	if (fractileYears) {
		if (metricFractilesData) {
			fractileYears.forEach((y) => {
				const data = metricFractilesData.find(({ref_year}) => ref_year === y);
				if (data) {
					fractileSeriesData.push(data.mean * (metricParams[metric].unit === "percent" ? 100 : 1));
				} else {
					fractileSeriesData.push(null);
				}
			});
		}
		if (companyMetricValues) {
			fractileYears.forEach((y) => {
				const data = companyMetricValues.find(({btpRefYear, metrics}) => btpRefYear === y && metrics);
				if (
					data &&
					typeof data.metrics[metric] !== "undefined" &&
					typeof data.metrics[metric].value !== "undefined" &&
					data.metrics[metric].value !== null &&
					!isNaN(Number(data.metrics[metric].value)) &&
					(data.metrics[metric].status === "valid" || data.metrics[metric].status === "proxy")
				) {
					companyMetricData = data.metrics[metric];

					companySeriesData.push({
						y: Number(data.metrics[metric].value) * (metricParams[metric].unit === "percent" ? 100 : 1),
						marker: {
							fillColor: data.metrics[metric].status === "proxy" ? "#C8CBCD" : undefined,
						},
						status: data.metrics[metric].status,
					});
				} else {
					companySeriesData.push(null);
				}
			});
		}
	}

	const chartOptions = cloneObject(highchartsCommonOptions);
	chartOptions.chart.height = 200;
	chartOptions.legend.enabled = false;
	chartOptions.xAxis.categories = fractileYears;
	chartOptions.series = [
		{
			name: "Entreprise",
			data: companySeriesData,
			color: "#FF9200",
			type: "line",
			lineWidth: 0,
			marker: {
				enabled: true,
				symbol: "circle",
				radius: 5,
				// lineWidth: 1,
				// lineColor: "#ffffff88",
			},
		},
		{
			name: "Moyenne sectorielle",
			data: fractileSeriesData,
			color: "#317FCB",
			lineWidth: 2.5,
			marker: {
				enabled: false,
			},
		},
	];
	chartOptions.yAxis[0].tickPixelInterval = 40;
	chartOptions.yAxis[0].labels.format =
		"{(floor value):,.0f},{(multiply (subtract value (floor value)) 10):,.0f}" +
		(metricParams[metric].unit === "percent" ? " %" : "");
	chartOptions.tooltip.formatter = function () {
		return (
			"<b>" +
			this.x +
			"</b><br/>" +
			this.points
				.map(
					(point) =>
						'<span style="color:' +
						point.color +
						';">●</span> ' +
						point.series.name +
						" : <b>" +
						formatNumberLocaleString(point.y, 1, true) +
						(metricParams[metric].unit === "percent" ? " %" : "") +
						"</b><br/>",
				)
				.join("")
		);
	};

	const handleUserMetricCommentChange = useCallback(
		(val) => {
			onUserMetricCommentChange(metric, val);
		},
		[metric, onUserMetricCommentChange],
	);

	// console.log(companySeriesData);
	return (
		<div className="metric-result-row">
			<div>
				<div className="metric-result-title">
					<div>{metricParams[metric].label}</div>
					<div>
						{fractileYears &&
							fractileYears.length > 0 &&
							companySeriesData[fractileYears.length - 1] &&
							companySeriesData[fractileYears.length - 1].y !== null && (
								<span
									className={companySeriesData[fractileYears.length - 1].status !== "valid" ? "color-disabled" : ""}
								>
									{formatNumberLocaleString(companySeriesData[fractileYears.length - 1].y, 1, true) +
										(metricParams[metric].unit === "percent" ? " %" : "")}
								</span>
							)}
					</div>
				</div>

				<div className="color-light-note fontSize09 marginTop05 marginBottom30">{metricParams[metric].description}</div>

				{fractileYears &&
				((companySeriesData &&
					companySeriesData.length > 0 &&
					companySeriesData.some((d) => d !== null && d.y !== null)) ||
					fractileSeriesData.some((v) => v !== null)) ? (
					<>
						<div className="metric-result-graph">
							<Chart options={chartOptions} />
						</div>

						<div className="metric-result-table">
							<Table>
								<thead>
									<tr>
										<th />
										{fractileYears.map((y) => (
											<th key={y}>{y}</th>
										))}
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<div
												className={
													"legend-marker circle " +
													(companySeriesData.some((d) => d !== null && d.y !== null) ? "bg-action-main" : "bg-inactive")
												}
											/>
											Entreprise
										</td>
										{companySeriesData &&
										companySeriesData.length > 0 &&
										companySeriesData.some((d) => d !== null && d.y !== null) ? (
											companySeriesData.map((d, idx) => (
												<td key={idx}>
													{d !== null && d.y !== null && (
														<span className={d.status !== "valid" ? "color-disabled" : ""}>
															{formatNumberLocaleString(d.y, 1, true) + (metricParams[metric].unit === "percent" ? " %" : "")}
															{d.status !== "valid" && (
																<InfoTip
																	className="marginLeft"
																	tooltip={
																		<>
																			Les données nécessaires au calcul de l&apos;indicateur sont incomplètes. Une
																			valeur approximée est affichée.
																			<br />
																			Veuillez compléter votre saisie pour améliorer la fiabilité de l&apos;indicateur.
																		</>
																	}
																/>
															)}
														</span>
													)}
												</td>
											))
										) : (
											<td colSpan={fractileYears.length}>
												<span className="italic">Aucune donnée disponible</span>
												<InfoTip
													className="marginLeft"
													tooltip={
														<>
															Des données nécessaires au calcul de l&apos;indicateur sont manquantes.
															<br />
															Veuillez compléter votre saisie.
														</>
													}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td>
											<div
												className={
													"legend-marker dash " +
													(fractileSeriesData.some((v) => v !== null) ? "bg-emphasis" : "bg-inactive")
												}
											/>
											Moyenne
										</td>
										{fractileSeriesData.map((v, idx) => (
											<td key={idx}>
												{v !== null &&
													formatNumberLocaleString(v, 1, true) + (metricParams[metric].unit === "percent" ? " %" : "")}
											</td>
										))}
									</tr>
								</tbody>
							</Table>
						</div>
					</>
				) : (
					<div className="italic fontSize09 centerAlign color-light-note paddingTop20">Aucune donnée disponible</div>
				)}
			</div>

			<div>
				{companyMetricData &&
					typeof companyMetricData.advisorComment !== "undefined" &&
					companyMetricData.advisorComment &&
					companyMetricData.advisorComment.length > 0 && (
						<div className="metric-result-expert-comment">
							<div className="metric-result-expert-comment-title">Le mot de l&apos;expert</div>
							<div className="metric-result-expert-comment-content">
								{companyMetricData.advisorComment}
								{companyMetricData.infoHtml && companyMetricData.infoHtml.length > 0 && (
									<div className="marginTop05">
										<div className="semiBold">Pour en savoir plus :</div>
										<div dangerouslySetInnerHTML={{__html: companyMetricData.infoHtml}} />
									</div>
								)}
							</div>
						</div>
					)}
				<div className="metric-result-user-comment">
					<div className="metric-result-user-comment-title">Commentaire libre</div>
					<div>
						<EditableTextArea
							inputClassName=""
							placeholder=""
							defaultValue={userMetricComments && userMetricComments[metric] ? userMetricComments[metric] : ""}
							maxCharacters={500}
							onChange={handleUserMetricCommentChange}
							displayNbCharHelper={true}
							changeEventDelay={600}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

function Report(props) {
	// console.log(props);

	const {userCode, userCompanyData, setUserSession, logUserEvent} = props;

	const navigate = useNavigate();

	if (!userCompanyData || !userCompanyData.siren) {
		navigate("/bienvenue");
	}

	const [usedSectorData, setUsedSectorData] = useState({
		activityCode: userCompanyData.companyActivityCode,
		activityLabel: userCompanyData.companyActivityLabel,
		btpSectorCode: userCompanyData.companyBTPSectorCode,
		btpSectorLabel: userCompanyData.companyBTPSectorLabel,
		btpSubSectorCode: userCompanyData.companyBTPSubSectorCode,
		btpSubSectorLabel: userCompanyData.companyBTPSubSectorLabel,
	});
	const [showActivityCodeManager, setShowActivityCodeManager] = useState(false);

	const handleChangeActivityCode = useCallback((data) => {
		if (data) {
			setUsedSectorData({...data});
			setComparisonSector(data.btpSectorCode);
		}
	}, []);

	const [comparisonSector, setComparisonSector] = useState(usedSectorData.btpSectorCode);

	const sectorOptions = useMemo(() => {
		const options = [
			{
				value: "_",
				label: "Tous secteurs",
			},
		];

		if (
			usedSectorData.btpSectorCode === "1" ||
			usedSectorData.btpSectorCode === "2" ||
			usedSectorData.btpSectorCode === "3"
		) {
			options.push({
				value: usedSectorData.btpSectorCode,
				label: usedSectorData.btpSectorLabel,
			});
		}

		if (usedSectorData.btpSectorCode === "3") {
			if (
				usedSectorData.btpSubSectorCode === "A" ||
				usedSectorData.btpSubSectorCode === "B" ||
				usedSectorData.btpSubSectorCode === "C" ||
				usedSectorData.btpSubSectorCode === "D" ||
				usedSectorData.btpSubSectorCode === "E"
			) {
				options.push({
					value: usedSectorData.btpSubSectorCode,
					label: usedSectorData.btpSubSectorLabel,
				});
			}
		}

		return options;
	}, [
		usedSectorData.btpSectorCode,
		usedSectorData.btpSectorLabel,
		usedSectorData.btpSubSectorCode,
		usedSectorData.btpSubSectorLabel,
	]);

	const [comparisonSizeCategory, setComparisonSizeCategory] = useState(userCompanyData.companySizeCategory);

	const sizeOptions = [
		{
			value: "_",
			label: "Toutes tailles",
		},
		{
			value: "GE",
			label: "Grandes entreprises",
		},
		{
			value: "ETI",
			label: "ETI",
		},
		{
			value: "PME",
			label: "PME",
		},
		{
			value: "TPE",
			label: "TPE",
		},
	];

	const [comparisonRegion, setComparisonRegion] = useState(userCompanyData.companyRegionCode);

	const regionOptions = [
		{
			value: "_",
			label: "France entière",
		},
	].concat(
		regionCodes.map(([code, label]) => ({
			value: code,
			label: label,
		})),
	);

	const [isLoadingResults, setLoadingResults] = useState(true); // TODO

	const [fractilesData, setFractilesData] = useState(null);

	const [sampleData, setSampleData] = useState(null);

	const [fractileYears, setFractileYears] = useState([]);
	const [fractilesLastYear, setFractilesLastYear] = useState(null);

	/*
	const fractileYears = useMemo(() => {
		
		const years = [];
		
		if (fractilesData) {
			const m0 = Object.keys(fractilesData)[0];
			if (fractilesData && fractilesData[m0]) {
				
				const sorted = fractilesData[m0].sort((a, b) => b.ref_year - a.ref_year);
				
				for (let iY = 0; iY < 5; iY++) {
					years.unshift(sorted[iY].ref_year);
				}
			}
		}
		
		return years;
	}, [fractilesData]);
	
	const lastYear = (fractileYears.length > 0 ? fractileYears[fractileYears.length - 1] : null);
	
	const initCompanyMetricValues = useMemo(() => {
		const r = {};
		fractileYears.forEach(y => {
			r[y] = {};
			metrics.forEach(m => {
				r[y][m] = null;
			});
		});
		return r;
	}, [fractileYears.length]);
	*/

	// const [companyMetricValues, setCompanyMetricValues] = useState(initCompanyMetricValues);
	const [companyMetricValues, setCompanyMetricValues] = useState(null);
	const [userMetricComments, setUserMetricComments] = useState(null);
	const [areCompanyMetricsConsistent, setCompanyMetricsConsistent] = useState(true);

	useEffect(() => {
		setLoadingResults(true);

		const reqConfig = {headers: {usercode: userCode}};

		const fractilesUrl =
			config.api.server +
			config.api.root +
			"/fractiles?" +
			(comparisonSector ? "sector=" + comparisonSector + "&" : "") +
			(comparisonSizeCategory ? "size=" + comparisonSizeCategory + "&" : "") +
			(comparisonRegion ? "region=" + comparisonRegion + "&" : "");

		axios
			.get(fractilesUrl, reqConfig)
			.then((fractilesResp) => {
				// console.log(fractilesResp);

				if (fractilesResp && fractilesResp.data.success && fractilesResp.data.data) {
					const fractiles = fractilesResp.data.data;

					setFractilesData(fractiles);

					//

					const years = [];

					const m0 = Object.keys(fractiles)[0];
					if (fractiles && fractiles[m0]) {
						const sorted = fractiles[m0].sort((a, b) => b.ref_year - a.ref_year);
						const nbYears = fractiles[m0].length;

						for (let iY = 0; iY < nbYears; iY++) {
							years.unshift(sorted[iY] ? sorted[iY].ref_year : null);
						}
					}

					setFractileYears(years);

					//

					const lastYear = years.length > 0 ? years[years.length - 1] : null;

					if (lastYear && fractiles[metrics[0]]) {
						const lastYearData = fractiles[metrics[0]].find(({ref_year}) => ref_year === lastYear);
						if (lastYearData) {
							setSampleData({
								year: lastYear,
								size: lastYearData.nb_entp,
								// size: 8,
							});
						}
					} else {
						setSampleData(null);
					}

					setFractilesLastYear(lastYear);

					//

					const metricsUrl =
						config.api.server +
						config.api.root +
						"/company/" +
						userCompanyData.siren +
						"/metrics?" +
						"sector=" +
						usedSectorData.btpSectorCode +
						"&" +
						"naf_code=" +
						usedSectorData.activityCode +
						"&" +
						"size=" +
						userCompanyData.companySizeCategory +
						"&" +
						"current_ref_year=" +
						lastYear;

					const metricCommentsUrl =
						config.api.server + config.api.root + "/company/" + userCompanyData.siren + "/metrics-comments";

					Promise.all([axios.get(metricsUrl, reqConfig), axios.get(metricCommentsUrl, reqConfig)]).then(
						([companyResp, metricCommentsResp]) => {
							// console.log(companyResp);
							// console.log(metricCommentsResp);

							setLoadingResults(false);

							if (companyResp && companyResp.data.success && companyResp.data.data) {
								setCompanyMetricValues(companyResp.data.data);
								
								setCompanyMetricsConsistent(![lastYear, lastYear - 1, lastYear - 2].some(year => {
									const yearCompanyMetrics = companyResp.data.data.find(({btpRefYear}) => btpRefYear === year);
									return (yearCompanyMetrics && yearCompanyMetrics.consistencyCheck === false);
								}));
							}

							if (metricCommentsResp && metricCommentsResp.data.success && metricCommentsResp.data.data) {
								setUserMetricComments(metricCommentsResp.data.data);
							}
						},
					);
				} else {
					setLoadingResults(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoadingResults(false);
			});
	}, [
		userCode,
		userCompanyData.siren,
		usedSectorData.btpSectorCode,
		usedSectorData.activityCode,
		userCompanyData.companySizeCategory,
		comparisonSector,
		comparisonSizeCategory,
		comparisonRegion,
	]);

	useEffect(() => {
		setUserSession({analysis: true});
		scroll.scrollToTop({duration: 200});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleUserMetricCommentChange = useCallback(
		(metric, comment) => {
			setUserMetricComments((prevState) => ({
				...prevState,
				[metric]: comment,
			}));

			//

			const reqConfig = {headers: {usercode: userCode}};

			const payload = {
				metricComments: {
					[metric]: comment,
				},
			};

			axios
				.post(
					config.api.server + config.api.root + "/company/" + userCompanyData.siren + "/metrics-comments",
					payload,
					reqConfig,
				)
				.catch((err) => {
					console.log(err);
				});

			setUserSession({user_analysis_comment: true});
		},
		[userCode, userCompanyData.siren, setUserSession],
	);

	const handleToFinancials = useCallback(() => {
		navigate("/etats-financiers");
	}, [navigate]);

	//

	const [isExportingReport, setIsExportingReport] = useState(false);
	const [showReportExportError, setShowReportExportError] = useState(false);

	const handleExportReport = useCallback(() => {
		setIsExportingReport(true);

		//

		const reqConfig = {headers: {usercode: userCode}};

		const url =
			config.api.server +
			config.api.root +
			"/company/" +
			userCompanyData.siren +
			"/report?" +
			"naf_code=" +
			usedSectorData.activityCode +
			"&" +
			"sector=" +
			comparisonSector +
			"&" +
			"size=" +
			comparisonSizeCategory +
			"&" +
			"region=" +
			comparisonRegion +
			"&" +
			"current_ref_year=" +
			fractilesLastYear;

		axios
			.get(url, reqConfig)
			.then((resp) => {
				// console.log(resp);

				setIsExportingReport(false);

				if (resp && resp.data.success && resp.data.data) {
					const result = resp.data.data;

					// const blob = new Blob([base64ToBytesArray(result.base64)], { type: "application/pdf" });
					const blob = new Blob([base64ToBytesArray(result.base64)], {
						type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
					});

					fileSaver.saveAs(blob, result.fileName);
				}
			})
			.catch((err) => {
				console.log(err);
				setIsExportingReport(false);
				setShowReportExportError(true);
			});

		setUserSession({pdf_export: true});
		logUserEvent({event: "pdf_export"});
	}, [
		comparisonRegion,
		comparisonSector,
		comparisonSizeCategory,
		fractilesLastYear,
		usedSectorData.activityCode,
		userCode,
		userCompanyData.siren,
		setUserSession,
		logUserEvent,
	]);

	const handleCloseReportExportError = useCallback(() => {
		setShowReportExportError(false);
	}, []);

	return (
		<>
			<div>
				<Card className="perimeter-manager">
					<div className="perimeter-manager-column">
						<CardTitle>Périmètre de comparaison</CardTitle>

						<div>
							<table className="comparison-perimeter-table">
								<tbody>
									<tr>
										<td className="verticalAlignTop">Votre code NAF</td>
										<td className="verticalAlignTop">
											<div className="noWrapEllipsis">
												{usedSectorData.activityCode + " - " + usedSectorData.activityLabel}
											</div>
											<LinkButton
												className="fontSize09"
												onClick={() => setShowActivityCodeManager(true)}
												disabled={isExportingReport}
											>
												Changer
											</LinkButton>
										</td>
									</tr>
									<tr>
										<td>Secteur d&apos;activité</td>
										<td>
											{/*userCompanyData.companyBTPSectorLabel*/}
											<Select
												className="comparison-perimeter-selector"
												selectedValue={comparisonSector}
												options={sectorOptions}
												onSelectOption={({value}) => setComparisonSector(value)}
												disabled={isExportingReport}
											/>
										</td>
									</tr>
									<tr>
										<td>Taille d&apos;entreprise</td>
										<td>
											{/*userCompanyData.companySizeCategory*/}
											<Select
												className="comparison-perimeter-selector"
												selectedValue={comparisonSizeCategory}
												options={sizeOptions}
												onSelectOption={({value}) => setComparisonSizeCategory(value)}
												disabled={isExportingReport}
											/>
										</td>
									</tr>
									<tr>
										<td>Zone géographique</td>
										<td>
											{/*userCompanyData.companyRegionLabel*/}
											<Select
												className="comparison-perimeter-selector"
												selectedValue={comparisonRegion}
												options={regionOptions}
												onSelectOption={({value}) => setComparisonRegion(value)}
												disabled={isExportingReport}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div className="sample-size-column">
						{sampleData ? (
							sampleData.size >= 10 ? (
								<div>
									<div>Votre entreprise sera comparée avec</div>
									<div className="figure">{formatNumberLocaleString(sampleData.size, 0, true)}</div>
									<div>{(sampleData.size > 1 ? " entreprises" : " entreprise") + " en " + sampleData.year}</div>
								</div>
							) : (
								<div>
									<div>
										L&apos;échantillon n&apos;est pas suffisamment significatif pour permettre une analyse comparative.
									</div>
								</div>
							)
						) : (
							<div>Aucune donnée comparative disponible sur le périmètre sélectionné</div>
						)}
					</div>
				</Card>

				{(isLoadingResults || (sampleData && sampleData.size >= 10)) && (
					<Card className="marginTop20">
						<div className="reportExportButtonTitle flex justifyFlexStart alignItemsBaseline">
							<div className="reportExportButtonWrapper">
								<CardTitle>Résultats de l&apos;analyse</CardTitle>
								<MainButton
									className="reportExportButton"
									disabled={isLoadingResults}
									onClick={handleExportReport}
									isLoading={isExportingReport}
								>
									Télécharger en PDF
								</MainButton>
							</div>
						</div>

						{isLoadingResults ? (
							<div className="centerAlign marginTop30 marginBottom30">
								<Loader />
							</div>
						) : (
							<div>
								<div>
									<InfoNote className="marginTop15">
										Vos indicateurs sont comparés aux 5 dernières années publiées de l&apos;étude BTP Banque.
										<br />
										L&apos;étude est mise à jour chaque année en septembre.
									</InfoNote>
								</div>

								{(!companyMetricValues || companyMetricValues.length === 0) && (
									<WarningNote className="marginTop15">
										Aucune donnée financière n&apos;est disponible pour l&apos;entreprise. Rendez-vous sur la page&nbsp;
										<LinkButton onClick={handleToFinancials}>États financiers</LinkButton>
										&nbsp;pour compléter vos données.
									</WarningNote>
								)}
								
								{!areCompanyMetricsConsistent && (
									<WarningNote className="marginTop15">
										Les données financières de l&apos;entreprise présentent certaines valeurs hors normes.<br/>
										Nous vous invitons à vérifier si l&apos;entreprise est bien éligible à l&apos;analyse comparative (société de production), et à
										compléter ses données si nécessaire.
									</WarningNote>
								)}

								<div>
									{metrics.map((metric) => (
										<MetricResult
											key={metric}
											metric={metric}
											metricResults={{}}
											// metricFractilesData={fractilesData && fractilesData[metric] ? fractilesData[metric] : null}
											fractilesData={fractilesData}
											companyMetricValues={companyMetricValues}
											fractileYears={fractileYears}
											userMetricComments={userMetricComments}
											onUserMetricCommentChange={handleUserMetricCommentChange}
										/>
									))}
								</div>
							</div>
						)}
					</Card>
				)}

				<BTPBanqueCard {...props} />
			</div>

			<ActivityCodeManager
				{...props}
				show={showActivityCodeManager}
				onHide={() => setShowActivityCodeManager(false)}
				onChange={handleChangeActivityCode}
			/>

			<StackableModal show={showReportExportError} dialogClassName="" onHide={handleCloseReportExportError} centered>
				<ModalBody>
					<p className="centerAlign">Une erreur s&apos;est produite.</p>
					<p className="centerAlign">Votre rapport n&apos;a pu être exporté.</p>
					<p className="centerAlign">
						Nous vous invitons à réessayer,
						<br />
						et si le problème persiste, à contacter le support.
					</p>
				</ModalBody>
				<ModalFooter>
					<MainButton onClick={handleCloseReportExportError}>Fermer</MainButton>
				</ModalFooter>
			</StackableModal>
		</>
	);
}

export default Report;

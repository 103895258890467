import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import config from "../config";
import {MainButton, SecondaryButton, LinkButton} from "./Button";
import {Card, CardTitle} from "./Card";
import {Select} from "./Select";
import {FinancialsOptions} from "./shared-components";
import axios from "axios";
import {animateScroll as scroll} from "react-scroll";
import InfoNote from "./InfoNote";
import ContactHelpDesk from "./ContactHelpDesk";
import BTPBanqueCard from "./BTPBanqueCard";
import Loader from "./Loader";

import "../App.css";
import "./Welcome.css";

class OpenCompanySelector extends React.Component {
	constructor(props) {
		super(props);

		this.companyInitValues = {
			siren: null,
			companyName: "",
			companyAddress: "",
			companyActivityLabel: "",
			companyActivityCode: "",
			companyBTPSectorLabel: "",
			companyBTPSectorCode: "",
			companyBTPSubSectorLabel: "",
			companyBTPSubSectorCode: "",
			companySizeCategory: "",
			companyRegionCode: "",
			companyRegionLabel: "",
			companyStatus: null,
			displayCompanyDetails: false,
			displayCompanySectorError: false,
			isLoadingCompanyOptions: false,
		};

		this.state = {
			...this.companyInitValues,
			companyOptions: [],
			inputValue: "",
		};
	}

	getCompanyOptions = (value) => {
		const cleanValue = value.trim().toLowerCase();
		const inputLength = cleanValue.length;

		if (inputLength > 2) {
			this.setState({isLoadingCompanyOptions: true});

			const reqConfig = {headers: {usercode: this.props.userCode}};
			axios
				.get(config.api.server + config.api.root + "/company/like/" + cleanValue, reqConfig)
				.then((response) => {
					// console.log(response);
					if (response.data.success && response.data.data) {
						this.setState({
							companyOptions: response.data.data.map((data) => ({
								...data,
								value: data.siren,
								label: data.siren + " - " + data.nom,
								selectedValueLabel: data.siren,
							})),
							isLoadingCompanyOptions: false,
						});
					} else {
						this.setState({
							companyOptions: [],
							isLoadingCompanyOptions: false,
						});
					}
				})
				.catch((error) => {
					console.log(error);
					this.setState({
						companyOptions: [],
						isLoadingCompanyOptions: false,
					});
				});
		}
	};

	retrieveCompanyBTPSector = (sectorCode) => {
		const reqConfig = {headers: {usercode: this.props.userCode}};
		return axios
			.get(config.api.server + config.api.root + "/btp-sector/" + sectorCode, reqConfig)
			.then((response) => {
				// console.log(response);
				if (response.data.success && response.data.data) {
					return {
						companyBTPSectorCode: response.data.data.code_secteur_btp,
						companyBTPSectorLabel: response.data.data.libelle_secteur_btp,
						companyBTPSubSectorCode: response.data.data.code_sous_secteur_btp,
						companyBTPSubSectorLabel: response.data.data.libelle_sous_secteur_btp,
					};
				} else return null;
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	};

	handleInputChange = (value, {action}) => {
		// console.log(value, action);

		if (action === "input-change") {
			this.getCompanyOptions(value);
			this.setState({
				inputValue: value,
				displayCompanySectorError: false,
			});
		}
	};

	handleSelectCompanyOption = (option) => {
		this.getCompanyOptions(option.siren);

		var companyAddress = "";
		if (option.complement_adresse !== null) companyAddress += option.complement_adresse;
		if (companyAddress.length > 0) companyAddress += ",";
		if (option.numero_voie !== null) companyAddress += " " + option.numero_voie;
		if (option.indice_repetition !== null) companyAddress += " " + option.indice_repetition;
		if (option.type_voie !== null) companyAddress += " " + option.type_voie;
		if (option.libelle_voie !== null) companyAddress += " " + option.libelle_voie;
		if (companyAddress.length > 0) companyAddress += ", ";
		if (option.code_postal !== null && option.code_postal.length > 0) companyAddress += option.code_postal + " ";
		if (option.libelle_commune !== null) companyAddress += option.libelle_commune;
		if (option.libelle_pays_etranger !== null) {
			if (companyAddress !== "") companyAddress += ", ";
			companyAddress += option.libelle_pays_etranger;
		}

		this.setState({
			siren: option.siren,
			companyName: option.nom,
			companyAddress: companyAddress,
			companyActivityLabel: option.libelle_activite_principale !== null ? option.libelle_activite_principale : "",
			companyActivityCode: option.code_activite_principale,
			companySizeCategory: option.categorie_entreprise,
			companyRegionCode: option.code_region,
			companyRegionLabel: option.libelle_region,
			companyStatus: option.etat_administratif,
			inputValue: option.siren,
		});

		this.retrieveCompanyBTPSector(option.code_activite_principale).then((sectorData) => {
			if (
				sectorData &&
				(sectorData.companyBTPSectorCode === "1" ||
					sectorData.companyBTPSectorCode === "2" ||
					sectorData.companyBTPSectorCode === "3")
			) {
				this.setState({
					displayCompanyDetails: true,
					displayCompanySectorError: false,
					...sectorData,
				});

				scroll.scrollToBottom({duration: 200});
			} else {
				this.setState({
					displayCompanyDetails: false,
					displayCompanySectorError: true,
				});
			}
		});
	};

	handleResetCompany = () => {
		this.setState({
			...this.companyInitValues,
			companyOptions: [],
			inputValue: "",
		});
	};

	handleChooseCompany = () => {
		this.props.setUserCompanyData({
			siren: this.state.siren,
			companyName: this.state.companyName,
			companyActivityCode: this.state.companyActivityCode,
			companyActivityLabel: this.state.companyActivityLabel,
			companyBTPSectorCode: this.state.companyBTPSectorCode,
			companyBTPSectorLabel: this.state.companyBTPSectorLabel,
			companyBTPSubSectorCode: this.state.companyBTPSubSectorCode,
			companyBTPSubSectorLabel: this.state.companyBTPSubSectorLabel,
			companySizeCategory: this.state.companySizeCategory,
			companyRegionCode: this.state.companyRegionCode,
			companyRegionLabel: this.state.companyRegionLabel,
		});

		this.props.goToFinancials();
	};

	render() {
		// console.log(this.state);

		return (
			<>
				<Select
					id="company-select"
					className="company-selector"
					searchable
					selectedValue={this.state.siren}
					options={this.state.companyOptions}
					onSelectOption={this.handleSelectCompanyOption}
					onInputChange={this.handleInputChange}
					placeholder="Saisissez le nom ou le code SIREN de l'entreprise"
					noOptionsMessage={() =>
						this.state.inputValue.length > 2 ? (
							this.state.isLoadingCompanyOptions ? (
								<Loader />
							) : (
								"Aucun choix disponible" + (this.state.inputValue.match(/[A-zÀ-ú]/) ? " - Saisissez un code SIREN" : "")
							)
						) : (
							"Saisissez un nom ou un code SIREN"
						)
					}
				/>

				{this.state.displayCompanySectorError && (
					<div className="marginTop10 color-error">
						L&apos;entreprise&nbsp;
						{this.state.companyName + " - " + this.state.siren}&nbsp;est d&apos;un secteur d&apos;activité autre que le
						bâtiment, et ne peut être sélectionnée pour l&apos;analyse sectorielle.
					</div>
				)}

				{this.state.displayCompanyDetails && (
					<Card className="company-details">
						<table id="company-details-table">
							<tbody>
								<tr>
									<td>Nom de l&apos;entreprise</td>
									<td>{this.state.companyName}</td>
								</tr>
								<tr>
									<td>Adresse</td>
									<td>{this.state.companyAddress}</td>
								</tr>
								<tr>
									<td>Code NAF</td>
									<td>{this.state.companyActivityCode}</td>
								</tr>
								<tr>
									<td>Secteur d&apos;activité principal</td>
									<td>{this.state.companyActivityLabel}</td>
								</tr>
							</tbody>
						</table>
						{/*companyStatus === "C" && (
					<div>Cette entreprise a cessé son activité.</div>
				)*/}

						<div className="marginTop15">
							<SecondaryButton onClick={this.handleResetCompany}>Changer d&apos;entreprise</SecondaryButton>
							<MainButton className="marginLeft10" onClick={this.handleChooseCompany}>
								Choisir cette entreprise
							</MainButton>
						</div>
					</Card>
				)}
			</>
		);
	}
}

function MultiCompanySelector(props) {
	const {userOwnCompanies, setUserCompanyData, goToFinancials} = props;

	const [selectedCompanyData, setSelectedCompanyData] = useState(null);
	const [companyOptions, setCompanyOptions] = useState([]);

	/*
	useEffect(() => {
		if (ownMultiCompanies && ownMultiCompanies.length > 0) {
			
			const reqConfig = {headers: {usercode: userCode}};
			
			Promise.all(ownMultiCompanies.map(companySiren => (
				axios.get(config.api.server + config.api.root + "/company/" + companySiren, reqConfig)
				.then((response) => {
					// console.log(response);
					if (response.data.success && response.data.data) {
						return response.data.data;
					}
					else return null;
				})
				.catch((error) => {
					console.log(error);
					return null;
				})
			)))
			.then(companiesData => {
				
				if (companiesData) {
					
					const companies = companiesData.filter(c => !!c);
					
					if (companies.length > 0) {
						
						setCompanyOptions(companiesData.filter(c => !!c).map(data => ({
							...data,
							value: data.siren,
							label: data.nom + " - SIREN " + data.siren,
						})));
						
						setSelectedCompanyData(companies[0]);
					}
					else {
						setCompanyOptions([]);
						setSelectedCompanyData(null);
					}
				}
				else {
					setCompanyOptions([]);
					setSelectedCompanyData(null);
				}
			});
		}
		else {
			setCompanyOptions([]);
			setSelectedCompanyData(null);
		}
	}, [ownMultiCompanies, userCode]);
	*/

	useEffect(() => {
		if (userOwnCompanies && userOwnCompanies.length > 0) {
			setCompanyOptions(
				userOwnCompanies.map((data) => ({
					...data,
					value: data.siren,
					label: data.nom + " - SIREN " + data.siren,
				})),
			);

			setSelectedCompanyData({
				siren: userOwnCompanies[0].siren,
				companyName: userOwnCompanies[0].nom,
				companyActivityLabel:
					userOwnCompanies[0].libelle_activite_principale !== null
						? userOwnCompanies[0].libelle_activite_principale
						: "",
				companyActivityCode: userOwnCompanies[0].code_activite_principale,
				companySizeCategory: userOwnCompanies[0].categorie_entreprise,
				companyRegionCode: userOwnCompanies[0].code_region,
				companyRegionLabel: userOwnCompanies[0].libelle_region,
				companyStatus: userOwnCompanies[0].etat_administratif,
				companyBTPSectorCode: userOwnCompanies[0].code_secteur_btp,
				companyBTPSectorLabel: userOwnCompanies[0].libelle_secteur_btp,
				companyBTPSubSectorCode: userOwnCompanies[0].code_sous_secteur_btp,
				companyBTPSubSectorLabel: userOwnCompanies[0].libelle_sous_secteur_btp,
			});
		} else {
			setCompanyOptions([]);
			setSelectedCompanyData(null);
		}
	}, [userOwnCompanies]);

	const handleSelectCompanyOption = useCallback((option) => {
		/*
		var companyAddress = "";
		if (option.complement_adresse !== null) companyAddress += option.complement_adresse;
		if (companyAddress.length > 0) companyAddress += ",";
		if (option.numero_voie !== null) companyAddress += " " + option.numero_voie;
		if (option.indice_repetition !== null) companyAddress += " " + option.indice_repetition;
		if (option.type_voie !== null) companyAddress += " " + option.type_voie;
		if (option.libelle_voie !== null) companyAddress += " " + option.libelle_voie;
		if (companyAddress.length > 0) companyAddress += ", ";
		if (option.code_postal !== null && option.code_postal.length > 0) companyAddress += option.code_postal + " ";
		if (option.libelle_commune !== null) companyAddress += option.libelle_commune;
		if (option.libelle_pays_etranger !== null) {
			if (companyAddress !== "") companyAddress += ", ";
			companyAddress += option.libelle_pays_etranger;
		}
		*/

		setSelectedCompanyData({
			siren: option.siren,
			companyName: option.nom,
			// companyAddress: companyAddress,
			companyActivityLabel: option.libelle_activite_principale !== null ? option.libelle_activite_principale : "",
			companyActivityCode: option.code_activite_principale,
			companySizeCategory: option.categorie_entreprise,
			companyRegionCode: option.code_region,
			companyRegionLabel: option.libelle_region,
			companyStatus: option.etat_administratif,
			companyBTPSectorCode: option.code_secteur_btp,
			companyBTPSectorLabel: option.libelle_secteur_btp,
			companyBTPSubSectorCode: option.code_sous_secteur_btp,
			companyBTPSubSectorLabel: option.libelle_sous_secteur_btp,
		});
	}, []);

	const handleChooseCompany = useCallback(() => {
		setUserCompanyData(selectedCompanyData);
		goToFinancials();
	}, [setUserCompanyData, selectedCompanyData, goToFinancials]);

	return (
		<>
			<Select
				id="company-select"
				className="company-selector"
				selectedValue={selectedCompanyData ? selectedCompanyData.siren : null}
				options={companyOptions}
				onSelectOption={handleSelectCompanyOption}
				placeholder="Aucune entreprise associée à votre compte"
			/>
			<div className="marginTop15">
				<MainButton onClick={handleChooseCompany} disabled={!selectedCompanyData}>
					Commencer
				</MainButton>
			</div>
		</>
	);
}

function MonoCompanySelector(props) {
	const {userOwnCompanies, setUserCompanyData, goToFinancials} = props;

	const [selectedCompanyData, setSelectedCompanyData] = useState(null);

	/*
	useEffect(() => {
		if (ownMonoCompany) {
			
			const reqConfig = {headers: {usercode: userCode}};
			
			axios.get(config.api.server + config.api.root + "/company/" + ownMonoCompany, reqConfig)
			.catch(err => {
				console.log(err);
				return null;
			})
			.then(companyResp => {
				
				if (companyResp && companyResp.data.success && companyResp.data.data) {
					
					const companyData = companyResp.data.data;
					
					var companyAddress = "";
					if (companyData.complement_adresse !== null) companyAddress += companyData.complement_adresse;
					if (companyAddress.length > 0) companyAddress += ",";
					if (companyData.numero_voie !== null) companyAddress += " " + companyData.numero_voie;
					if (companyData.indice_repetition !== null) companyAddress += " " + companyData.indice_repetition;
					if (companyData.type_voie !== null) companyAddress += " " + companyData.type_voie;
					if (companyData.libelle_voie !== null) companyAddress += " " + companyData.libelle_voie;
					if (companyAddress.length > 0) companyAddress += ", ";
					if (companyData.code_postal !== null && companyData.code_postal.length > 0) companyAddress += companyData.code_postal + " ";
					if (companyData.libelle_commune !== null) companyAddress += companyData.libelle_commune;
					if (companyData.libelle_pays_etranger !== null) {
						if (companyAddress !== "") companyAddress += ", ";
						companyAddress += companyData.libelle_pays_etranger;
					}

					setSelectedCompanyData({
						siren: companyData.siren,
						companyName: companyData.nom,
						companyAddress: companyAddress,
						companyActivityLabel: companyData.libelle_activite_principale !== null ? companyData.libelle_activite_principale : "",
						companyActivityCode: companyData.code_activite_principale,
						companySizeCategory: companyData.categorie_entreprise,
						companyRegionCode: companyData.code_region,
						companyRegionLabel: companyData.libelle_region,
						companyStatus: companyData.etat_administratif,
						companyBTPSectorCode: companyData.code_secteur_btp,
						companyBTPSectorLabel: companyData.libelle_secteur_btp,
						companyBTPSubSectorCode: companyData.code_sous_secteur_btp,
						companyBTPSubSectorLabel: companyData.libelle_sous_secteur_btp,
					});
				}
				else {
					setSelectedCompanyData(null);
				}
			});
		}
		else {
			setSelectedCompanyData(null);
		}
	}, [ownMonoCompany, userCode]);
	*/

	useEffect(() => {
		if (userOwnCompanies && userOwnCompanies[0]) {
			setSelectedCompanyData({
				siren: userOwnCompanies[0].siren,
				companyName: userOwnCompanies[0].nom,
				companyActivityLabel:
					userOwnCompanies[0].libelle_activite_principale !== null
						? userOwnCompanies[0].libelle_activite_principale
						: "",
				companyActivityCode: userOwnCompanies[0].code_activite_principale,
				companySizeCategory: userOwnCompanies[0].categorie_entreprise,
				companyRegionCode: userOwnCompanies[0].code_region,
				companyRegionLabel: userOwnCompanies[0].libelle_region,
				companyStatus: userOwnCompanies[0].etat_administratif,
				companyBTPSectorCode: userOwnCompanies[0].code_secteur_btp,
				companyBTPSectorLabel: userOwnCompanies[0].libelle_secteur_btp,
				companyBTPSubSectorCode: userOwnCompanies[0].code_sous_secteur_btp,
				companyBTPSubSectorLabel: userOwnCompanies[0].libelle_sous_secteur_btp,
			});
		} else {
			setSelectedCompanyData(null);
		}
	}, [userOwnCompanies]);

	const handleChooseCompany = useCallback(() => {
		setUserCompanyData(selectedCompanyData);
		goToFinancials();
	}, [setUserCompanyData, selectedCompanyData, goToFinancials]);

	return (
		<>
			{selectedCompanyData ? (
				<div className="color-emphasis">
					{selectedCompanyData.companyName + " - SIREN " + selectedCompanyData.siren}
				</div>
			) : (
				<div className="italic">Aucune information disponible</div>
			)}
			<div className="marginTop15">
				<MainButton onClick={handleChooseCompany} disabled={!selectedCompanyData}>
					Commencer
				</MainButton>
			</div>
		</>
	);
}

function Welcome(props) {
	// console.log(props);

	const nav = useNavigate();

	const goToFinancials = useCallback(() => {
		nav("/etats-financiers");
	}, [nav]);

	return (
		<>
			<Card>
				<CardTitle>Bienvenue sur l&apos;outil BatiPerf</CardTitle>

				<div>
					<p>
						L&apos;outil BatiPerf vous permet de comparer les données financières de votre entreprise aux moyennes du
						secteur, sans préjuger du niveau de ces dernières (qui peuvent être estimées bonnes ou pas par un expert).
					</p>
					<p>
						BatiPerf est dédié à des sociétés d&apos;exploitation munies d&apos;un code SIREN. L&apos;outil est inadapté aux holdings, comptes consolidés et SCI.
					</p>
					<p className="marginTop15">
						Si vos états financiers sont disponibles au Répertoire National des Entreprises (RNE), ils sont récupérés.
					</p>
					<p>Vous pouvez compléter les années manquantes par :</p>
					<FinancialsOptions />
				</div>

				<div className="marginTop30">
					{props.userProfile === "mono-contextual" && (
						<>
							<div className="marginBottom05">
								<div>Votre compte ffbatiment se réfère à cette entreprise :</div>
							</div>
							<MonoCompanySelector {...props} goToFinancials={goToFinancials} />

							<InfoNote className="marginTop10">
								Si ces informations ne correspondent pas à votre entreprise, ou si vous souhaitez évaluer une autre
								entreprise, indiquez-le-nous via le&nbsp;
								<ContactHelpDesk
									{...props}
									triggerComponent={(onClick) => <LinkButton onClick={onClick}>Formulaire de contact</LinkButton>}
								/>
							</InfoNote>
						</>
					)}

					{props.userProfile === "multi-contextual" && (
						<>
							<div className="marginBottom05">
								<div>Votre compte ffbatiment recense plusieurs entreprises.</div>
								<div className="marginTop05">Pour commencer, choisissez celle que vous souhaitez comparer :</div>
							</div>
							<MultiCompanySelector {...props} goToFinancials={goToFinancials} />

							<InfoNote className="marginTop10">
								Si ces informations ne correspondent pas à votre entreprise, ou si vous souhaitez évaluer une autre
								entreprise, indiquez-le-nous via le&nbsp;
								<ContactHelpDesk
									{...props}
									triggerComponent={(onClick) => <LinkButton onClick={onClick}>Formulaire de contact</LinkButton>}
								/>
							</InfoNote>
						</>
					)}

					{props.userProfile === "no-context" && (
						<>
							<div className="marginBottom05">Indiquez l&apos;entreprise que vous souhaitez comparer :</div>
							<OpenCompanySelector {...props} goToFinancials={goToFinancials} />
						</>
					)}
				</div>
			</Card>

			<BTPBanqueCard {...props} />
		</>
	);
}

export default Welcome;
